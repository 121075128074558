import { NgModule } from '@angular/core';
import{PhoneMaskDirective} from './phone.directive'






@NgModule({
    exports: [PhoneMaskDirective],
    declarations: [PhoneMaskDirective]
  })
  export class SharedModule {}