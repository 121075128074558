
<!-- Generated template for the DropdownlistComponent component -->
<div class="modal-body">
  <div>
    <!-- <div style="position: relative;bottom:1em;">
    <button style="width:40%;"  class="save_btn" (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Within Aspect Ratio'}}</button>
    </div> -->
    <image-cropper style="height: 280px;" 
    [imageChangedEvent]="imageChangedEvent"
     [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"    
    [containWithinAspectRatio]="containWithinAspectRatio" 
     [resizeToWidth]="256"
    [cropperMinWidth]="28"
     format="png"
      (imageCropped)="imageCropped($event)"
      (toggleContainWithinAspectRatio)="toggleContainWithinAspectRatio()"

    (imageLoaded)="imageLoaded()" 
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div>
    <div class="row" style="padding-top: 1em;padding-bottom: 1em;justify-content: center;">
      <div class="col-md-3">
          <button (click)="Save()" class="save_btn" type="submit">Save</button>
      </div>
      <div class="col-md-3">
          <button (click)="closeModal()" class="cancel_btn" type="button">Cancel</button>
      </div>
  </div>
    <!-- <div style="text-align: center;">
        <div class="row" style="padding-top: 1em;">
            <div class="col-md-3">
                <button (click)="Save()" class="save_btn" type="submit">Save</button>
            </div>
            <div class="col-md-3">
                <button (click)="closeModal()" class="cancel_btn" type="button">Cancel</button>
            </div>
        </div>    </div> -->
  </div>
</div>
  
